import {
  TimelineRow,
  TimeRegistrationTimelinesTableHeaderMeta,
} from '@bas/shared/models';
import { colors, fontSizesWeb } from '@bas/theme';
import workingDayBackground from '@bas/ui/assets/Resources/workingDayBackground.svg';
import { Box, styled } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import { Header, Row } from '@tanstack/table-core/src/types';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { ReactElement, useCallback, useMemo } from 'react';

type TimeRegistrationTimelinesTableBodyProps = {
  headers: Header<TimelineRow, unknown>[];
  rows: Row<TimelineRow>[];
  className?: string;
  hourCellWidth: number;
};

const TimeRegistrationTimelinesTableBody = ({
  headers,
  rows,
  className,
  hourCellWidth,
}: TimeRegistrationTimelinesTableBodyProps): ReactElement => {
  const leftStickyHeaders = headers.filter(
    (h) =>
      (h.column.columnDef.meta as TimeRegistrationTimelinesTableHeaderMeta)
        ?.sticky === 'left',
  );
  const rightStickyHeaders = headers.filter(
    (h) =>
      (h.column.columnDef.meta as TimeRegistrationTimelinesTableHeaderMeta)
        ?.sticky === 'right',
  );

  const renderCell = (cell: Cell<TimelineRow, unknown>) => {
    const meta = cell.column.columnDef
      .meta as TimeRegistrationTimelinesTableHeaderMeta;
    const sticky = meta?.sticky === 'left' || meta?.sticky === 'right';
    const stickyOffset = meta?.stickyOffset ?? 0;
    const isLastStickyHeader =
      sticky &&
      headers.findIndex((h) => h.id === cell.column.id) ===
        (meta?.sticky === 'left'
          ? leftStickyHeaders.length - 1
          : rightStickyHeaders.length - 1);

    const isHourColumn = meta?.isHourColumn;

    return (
      <Box
        key={cell.id}
        className={clsx('Bas-TimeRegistrationTimelinesTable-Cell', {
          'Bas-TimeRegistrationTimelinesTable-Cell-Sticky': sticky,
          'Bas-TimeRegistrationTimelinesTable-Cell-Sticky-Last':
            sticky && isLastStickyHeader,
          'Bas-TimeRegistrationTimelinesTable-Cell-Hour': isHourColumn,
        })}
        sx={{
          width: cell.column.getSize(),
          left: meta?.sticky === 'left' ? stickyOffset : undefined,
          right: meta?.sticky === 'right' ? stickyOffset : undefined,
        }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Box>
    );
  };

  const stickyColumnsWidth = useMemo(
    () => leftStickyHeaders.reduce((acc, h) => acc + h.column.getSize(), 0),
    [leftStickyHeaders],
  );

  const calculateEntryPositioning = useCallback(
    ({ start, end }: { start: Dayjs; end: Dayjs }) => {
      const hourPosition = start.hour() * hourCellWidth;
      const minutePosition = (start.minute() / 60) * hourCellWidth;
      const leftPosition = stickyColumnsWidth + hourPosition + minutePosition;
      const hourEndPosition = end.hour() * hourCellWidth;
      const minuteEndPosition = (end.minute() / 60) * hourCellWidth;
      const rightPosition =
        stickyColumnsWidth + hourEndPosition + minuteEndPosition;

      // For width calculation (instead of setting right: 0)
      const width = rightPosition - leftPosition;
      return { leftPosition, rightPosition, width };
    },
    [stickyColumnsWidth, hourCellWidth],
  );

  // Helper function to check if two time ranges are adjacent
  const areTimesAdjacent = useCallback(
    (end1: Date | Dayjs | string, start2: Date | Dayjs | string): boolean => {
      const end1Time = dayjs(end1);
      const start2Time = dayjs(start2);
      // Check if they're exactly adjacent (within 1 minute tolerance)
      return Math.abs(end1Time.diff(start2Time, 'minute')) <= 1;
    },
    [],
  );

  const renderEntry = useCallback(
    (
      entry: TimelineRow['entries'][0],
      index: number,
      entries: TimelineRow['entries'],
    ) => {
      const start = dayjs(entry.start);
      const end = dayjs(entry.end);
      const { leftPosition, width } = calculateEntryPositioning({ start, end });

      // Check for adjacent entries
      const hasPreviousAdjacent = entries.some(
        (e, i) => i !== index && areTimesAdjacent(e.end, entry.start),
      );
      const hasNextAdjacent = entries.some(
        (e, i) => i !== index && areTimesAdjacent(entry.end, e.start),
      );

      return (
        <Box
          key={`${entry.start}-${entry.end}-${entry.entryType}`}
          className={clsx(
            'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry',
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsPlanned':
                entry.planned,
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsFromForeman':
                entry.accordingToForeman,
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved':
                entry.approved,
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsRegistered':
                entry.registered,
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsInternalWork':
                entry.entryType === 'internal-work',
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsFieldWork':
                entry.entryType === 'field-work',
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsBreakTime':
                entry.entryType === 'break-time',
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved':
                entry.approved,
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsSick':
                entry.entryType === 'sick',
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsLeave':
                entry.entryType === 'leave',
            },
            // Apply border radius classes
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--NoLeftRadius':
                hasPreviousAdjacent,
            },
            {
              'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--NoRightRadius':
                hasNextAdjacent,
            },
          )}
          sx={{
            left: leftPosition,
            width,
          }}
        />
      );
    },
    [calculateEntryPositioning, areTimesAdjacent],
  );

  const renderExpectedWorkingDayEntry = useCallback(
    (row: TimelineRow) => {
      const start = dayjs(row.expectedStartTimeOfWorkDay);
      const end = dayjs(row.expectedEndTimeOfWorkDay);
      const { leftPosition, width } = calculateEntryPositioning({ start, end });

      return (
        <Box
          className={clsx(
            'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry',
            'Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsExpectedWorkingDay',
          )}
          sx={{
            left: leftPosition,
            width,
          }}
        />
      );
    },
    [calculateEntryPositioning],
  );

  return (
    <Box className={clsx(className, 'Bas-TimeRegistrationTimelinesTable-Body')}>
      {rows.map((row) => (
        <Box key={row.id} className="Bas-TimeRegistrationTimelinesTable-Row">
          {row.getVisibleCells().map(renderCell)}
          <Box className="Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Root">
            {/* Pass index and full entries array to renderEntry */}
            {row.original.entries.map((entry, index, entries) =>
              renderEntry(entry, index, entries),
            )}
            {renderExpectedWorkingDayEntry(row.original)}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const StyledTimeRegistrationTimelinesTableBody = styled(
  TimeRegistrationTimelinesTableBody,
)`
  &.Bas-TimeRegistrationTimelinesTable-Body {
    width: 100%;

    .Bas-TimeRegistrationTimelinesTable-Row {
      display: flex;
      position: relative;
      border-bottom: 0.5px solid ${colors.lila[400]};

      .Bas-TimeRegistrationTimelinesTable-Cell {
        box-sizing: border-box;
        height: 60px;
        font-size: ${fontSizesWeb.sm};
        padding-left: ${({ theme }) => theme.spacing(1)};
        display: flex;
        align-items: center;

        &.Bas-TimeRegistrationTimelinesTable-Cell-Hour {
          border-left: 0.5px solid ${colors.lila[400]};
        }
      }

      .Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry {
        position: absolute;
        top: 12px;
        bottom: 12px;
        box-sizing: border-box;
        border-radius: 4px;
        z-index: 2;

        /* Conditional border radius classes */
        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--NoLeftRadius {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--NoRightRadius {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsExpectedWorkingDay {
          background: url("${workingDayBackground}");
          z-index: 1;
          opacity: 0.3;
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsFieldWork {
          background: #00A74A; /* green.500 - Most prominent for planned */

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsRegistered {
            background: #8EDA96; /* green.300 - Medium prominence for registered */
          }

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved {
            background: #BFE6C2; /* Medium-light green - More visible for approved */
          }
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsInternalWork {
          background: #3A78F2; /* blue.500 - Most prominent for planned */

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsRegistered {
            background: #ACC6F9; /* blue.200 - Medium prominence for registered */
          }

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved {
            background: #C5D7F7; /* Medium-light blue - More visible for approved */
          }
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsSick {
          background: #e54e44; /* red.700 - More prominent for registered */

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved {
            background: #F7C1BE; /* Medium-light red - More visible for approved */
          }
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsLeave {
          background: #FFA04D; /* orange.750 - More prominent for registered */

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved {
            background: #FFDCB0; /* Medium-light orange - More visible for approved */
          }
        }

        &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsBreakTime {
          background: #7A7887; /* lila.700 - Most prominent for planned */

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsRegistered {
            background: #A2A1AE; /* lila.600 - Medium prominence for registered */
          }

          &.Bas-TimeRegistrationTimelinesTable-Row-TimeEntries-Entry--IsApproved {
            background: #CDCDD6; /* Medium-light lila - More visible for approved */
          }
        }
      }
`;

export default StyledTimeRegistrationTimelinesTableBody;
