import {
  TimelineRow,
  TimeRegistrationTimelinesTableHeaderMeta,
} from '@bas/shared/models';
import { colors, fontSizes, lineHeights } from '@bas/theme';
import { Box, styled, Typography } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { Header } from '@tanstack/table-core/src/types';
import clsx from 'clsx';
import { ReactElement } from 'react';

type TimeRegistrationTimelinesTableHeaderProps = {
  headers: Header<TimelineRow, unknown>[];
  className?: string;
};

const TimeRegistrationTimelinesTableHeader = ({
  headers,
  className,
}: TimeRegistrationTimelinesTableHeaderProps): ReactElement => {
  const leftStickyHeaders = headers.filter(
    (h) =>
      (h.column.columnDef.meta as TimeRegistrationTimelinesTableHeaderMeta)
        ?.sticky === 'left',
  );
  const rightStickyHeaders = headers.filter(
    (h) =>
      (h.column.columnDef.meta as TimeRegistrationTimelinesTableHeaderMeta)
        ?.sticky === 'right',
  );

  const renderHeader = (header: Header<TimelineRow, unknown>) => {
    const meta = header.column.columnDef
      .meta as TimeRegistrationTimelinesTableHeaderMeta;
    const sticky = meta?.sticky === 'left' || meta?.sticky === 'right';
    const stickyOffset = meta?.stickyOffset ?? 0;
    const isLastStickyHeader =
      sticky &&
      headers.indexOf(header) ===
        (meta?.sticky === 'left'
          ? leftStickyHeaders.length - 1
          : rightStickyHeaders.length - 1);

    const isHourColumn = meta?.isHourColumn;
    const showHourLabel = meta?.showHourLabel ?? false;

    if (isHourColumn && !showHourLabel) {
      return null;
    }

    return (
      <Box
        key={header.id}
        className={clsx(
          'Bas-TimeRegistrationTimelinesTable-Header-Cell',
          'Bas-TimeRegistrationTimelinesTable-Cell',
          {
            'Bas-TimeRegistrationTimelinesTable-Cell-Sticky': sticky,
            'Bas-TimeRegistrationTimelinesTable-Cell-Sticky-Last':
              sticky && isLastStickyHeader,
            'Bas-TimeRegistrationTimelinesTable-Cell-Hour': isHourColumn,
          },
        )}
        sx={{
          width: header.getSize() * header.colSpan,
          left: meta?.sticky === 'left' ? stickyOffset : undefined,
          right: meta?.sticky === 'right' ? stickyOffset : undefined,
          justifyContent:
            meta?.headerAlign === 'center' ? 'center' : 'flex-start',
        }}
      >
        <Typography className="Bas-TimeRegistrationTimelinesTable-Header-Cell-Label">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      className={clsx('Bas-TimeRegistrationTimelinesTable-Header', className)}
    >
      {headers.map(renderHeader)}
    </Box>
  );
};

const StyledTimeRegistrationTimelinesTableHeader = styled(
  TimeRegistrationTimelinesTableHeader,
)`
  &.Bas-TimeRegistrationTimelinesTable-Header {
    display: flex;
    border-bottom: 0.5px solid ${colors.lila[400]};
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: ${colors.white};

    .Bas-TimeRegistrationTimelinesTable-Header-Cell {
      position: relative;
      box-sizing: border-box;
      padding: ${({ theme }) => theme.spacing(1)};
      display: flex;
      align-items: center;

      .MuiTypography-root {
        font-size: ${fontSizes.sm}px;
        font-weight: bold;
        line-height: ${lineHeights.sm}px;
        color: ${colors.lila[800]};
      }

      &.Bas-TimeRegistrationTimelinesTable-Cell-Hour {
        .MuiTypography-root {
          position: absolute;
          left: 0;
          transform: translateX(-50%);
        }
      }
    }
  }
`;

export default StyledTimeRegistrationTimelinesTableHeader;
