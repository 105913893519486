import { TimeOffRegistrationStatus } from '@bas/hrm-domain/models';
import { Chip } from '@bas/ui/web/atoms';
import { Badge, Box } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type TimeOffRegistrationStatusChipProps = {
  status: TimeOffRegistrationStatus;
  isSickLeave?: boolean;
};

const timeOffRegistrationStatusColors: {
  [key: string]:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
} = {
  [TimeOffRegistrationStatus.PENDING]: 'warning',
  [TimeOffRegistrationStatus.APPROVED]: 'success',
  [TimeOffRegistrationStatus.REJECTED]: 'error',
  [TimeOffRegistrationStatus.CANCELLED]: 'error',
  [TimeOffRegistrationStatus.COMPLETED]: 'success',
};

const TimeOffRegistrationStatusChip = ({
  status,
  isSickLeave,
}: TimeOffRegistrationStatusChipProps): ReactElement => {
  const color =
    isSickLeave && status === TimeOffRegistrationStatus.APPROVED
      ? 'warning'
      : timeOffRegistrationStatusColors[status] || 'primary';

  return (
    <Chip
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {color && <Badge color={color} badgeContent=" " variant="dot" />}
          <span style={{ paddingLeft: '8px' }}>
            {isSickLeave && status === TimeOffRegistrationStatus.APPROVED && (
              <FormattedMessage id="label.sick" />
            )}
            {isSickLeave && status === TimeOffRegistrationStatus.COMPLETED && (
              <FormattedMessage id="label.isBetter" />
            )}
            {(!isSickLeave ||
              ![
                TimeOffRegistrationStatus.APPROVED,
                TimeOffRegistrationStatus.COMPLETED,
              ].includes(status)) && (
              <FormattedMessage id={`timeOffRegistrationStatus.${status}`} />
            )}
          </span>
        </Box>
      }
    />
  );
};

export default TimeOffRegistrationStatusChip;
